import React, { useContext } from "react";
import {
  FacebookShareButton,
  LineShareButton,
  TwitterShareButton,
} from "react-share";
import styled from "styled-components";
import { message } from "antd";
import { Facebook, Twitter } from "@styled-icons/boxicons-logos";
import { Line } from "@styled-icons/fa-brands";
import { Link } from "@styled-icons/boxicons-regular";
import * as Theme from "../../Theme";
import Constants from "../../constants";
import { Context } from "../../AppContext";

export default function ShareLinks(props) {
  const app = useContext(Context);
  const { instance, id, style } = props;
  const [reurl, setReurl] = React.useState(`${Constants.webUrl}work?id=${id}`);

  const defaultProps = {
    disabled: !id,
    url: reurl,
    resetButtonStyle: false,
    className: "link",
  };
  const IconProps = {
    size: 22,
    color: "#707070",
  };

  const getReurl = React.useCallback(async () => {
    try {
      let text = await app.actions.getReurl({
        id: instance.id,
        image: encodeURIComponent(instance.files[0].expected_url),
        title: encodeURIComponent(instance.title),
        outline: encodeURIComponent(instance.title),
        path: "work",
      });

      setReurl(text);
    } catch (err) {
      console.warn(err);
    }
  }, [app.actions, instance.id, instance.files, instance.title]);

  const copyShareLink = () => {
    navigator.clipboard.writeText(reurl);
    message.success("複製分享連結成功。");
  };

  React.useEffect(() => {
    getReurl();
  }, [getReurl]);

  return (
    <Wrapper
      style={{
        display: "flex",
        alignItems: "center",
        ...style,
      }}
    >
      <div style={{ margin: "0 5px", color: "#707070", fontSize: 16 }}>
        SHARE
      </div>
      <FacebookShareButton quote={instance.title} {...defaultProps}>
        <Facebook {...IconProps} />
      </FacebookShareButton>
      <LineShareButton title={instance.title} {...defaultProps}>
        <Line {...IconProps} />
      </LineShareButton>
      <TwitterShareButton title={instance.title} {...defaultProps}>
        <Twitter {...IconProps} />
      </TwitterShareButton>
      <div className="link" onClick={() => copyShareLink()}>
        <Link {...IconProps} />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  > .link {
    margin-right: 10px;
    outline: 0;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      opacity: 0.5;
    }
  }
`;
